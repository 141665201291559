<template>
  <div>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.addCustomerForm"
    />
    <div class="card">
      <div class="card-header">
        <div class="card-title mb-0">Add New Customer Profile</div>
      </div>
      <div class="card-body">
        <ValidationObserver ref="addCustomerForm">
          <form @submit.prevent="onSubmit">
            <AdminCustomerAddForm
              :customer="customer"
              :usernameIsAvailable="
                !invalidUsername &&
                  this.customer.username &&
                  this.customer.username !== ''
              "
              :usernameIsNotAvailable="usernameChecked && !usernameAvailable"
              :emailInUse="emailChecked && !emailAvailable"
            />
            <div class="d-flex mt-2">
              <div>
                <button
                  type="submit"
                  class="btn btn-lg btn-primary mr-2"
                  :disabled="invalidUsername || invalidEmail"
                >
                  Save
                </button>
              </div>
              <div>
                <button
                  class="btn btn-lg mr-2"
                  @click="pushToSearch"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </div>
            <p class="mt-2">
              <span style="color: red">*</span> Indicates a required field
            </p>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import AdminCustomerAddForm from "@/components/admin/AdminCustomerAddForm.vue";
import AdminProfileService from "@/services/admin/AdminProfileService.js";
import ProfileService from "@/services/ProfileService.js";
import { ValidationObserver } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";

export default {
  name: "AdminCustomerAdd",
  title: "Admin - Customer Add",
  components: {
    ValidationObserver,
    FormErrorAlert,
    AdminCustomerAddForm
  },
  data() {
    return {
      customer: {
        country: "US"
      },
      errors: [],
      usernameAvailable: false,
      usernameChecked: false,
      loadingUsername: false,
      usernameTimeout: null,
      emailAvailable: false,
      emailChecked: false,
      loadingEmail: false,
      emailTimeout: null
    };
  },
  watch: {
    "customer.username"() {
      clearTimeout(this.usernameTimeout);
      var self = this;
      this.usernameTimeout = setTimeout(() => {
        self.checkUsername(self.customer.username);
      }, 1000);
    },
    "customer.email"() {
      clearTimeout(this.emailTimeout);
      var self = this;
      this.emailTimeout = setTimeout(() => {
        self.checkEmail(self.customer.email);
      }, 1000);
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    invalidUsername() {
      return this.customer.username
        ? this.customer.username === ""
          ? false
          : !(this.usernameAvailable && this.usernameChecked)
        : false;
    },
    invalidEmail() {
      return !(this.emailAvailable && this.emailChecked);
    }
  },
  methods: {
    async checkUsername(username) {
      if (
        username &&
        username.match(/^[A-Za-z0-9-_=.]+$/) &&
        username.length > 7 &&
        username.length < 21
      ) {
        const profileService = new ProfileService(this.tenantId);
        this.loadingUsername = true;
        this.usernameAvailable = false;
        this.usernameChecked = false;
        const response = await profileService.checkUsernameAvailability(
          username
        );
        this.usernameAvailable =
          response.statusCode === "Success" && response.data === true;
        this.usernameChecked = true;
        this.loadingUsername = false;
      } else {
        this.usernameAvailable = this.usernameChecked = false;
      }
    },
    async checkEmail(email) {
      if (email.match(/\S+@\S+\.\S+/) && email.length < 257) {
        const profileService = new ProfileService(this.tenantId);
        this.loadingEmail = true;
        this.emailAvailable = false;
        this.emailChecked = false;
        const response = await profileService.checkEmailAvailability(email);
        this.emailAvailable =
          response.statusCode === "Success" && response.data === true;
        this.emailChecked = true;
        this.loadingEmail = false;
      } else {
        this.emailAvailable = this.emailChecked = false;
      }
    },
    async onSubmit() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      this.$refs.addCustomerForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.addCustomerForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.addCustomerForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            this.$store.commit("auth/setLoading", true);
            const profileService = new AdminProfileService(tenantId);
            let createUser = false;
            this.customer.state = this.customer.state.trim();
            this.customer.zipCode = this.customer.zipCode ?? null;
            this.customer.primaryPhoneNumber =
              this.customer.primaryPhoneNumber ?? null;
            this.customer.alternatePhoneNumber =
              this.customer.alternatePhoneNumber ?? null;
            if (!this.customer.comments) {
              this.customer.comments = "None";
            }
            if (this.customer.username && this.customer.username !== "") {
              createUser = true;
            }
            const newCustomerResponse = await profileService.createCustomerProfileAdmin(
              this.customer,
              createUser
            );
            if (newCustomerResponse.statusCode == "Success") {
              const newCustomer = newCustomerResponse.data;
              this.$router.push("/admin/customer-search").catch(() => {});
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message: `Customer ${newCustomer.firstName} ${newCustomer.lastName} was successfully added`,
                layer: "admin"
              });
            } else {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Error in adding customer.",
                layer: "admin"
              });
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          } finally {
            this.$store.commit("auth/setLoading", false);
          }
        }
      });
    },
    pushToSearch() {
      this.$router.push("/admin/customer-search").catch(() => {});
    }
  }
};
</script>
